@font-face {
  font-family: "Vodafone Rg";
  src: local("Vodafone Rg"),
    url(./assets/fonts/VodafoneRg.ttf) format("truetype");
}

@font-face {
  font-family: "Vodafone Lt";
  src: local("Vodafone Lt"),
    url(./assets/fonts/VodafoneLtRegular.ttf) format("truetype");
}

html, #root, #converse-container, #conversejs, .spinner-custom-container {
  height: 100%;
}

body {
  height: 98%;
  font: 16px "Vodafone Rg", monospace;
  background: linear-gradient(180deg, #ffffff 0%, #ebebeb 40%);
}

#conversejs .chatroom .box-flyout .chatroom-body,
#conversejs {
  background: #ebebeb;
}

#conversejs a,
#conversejs article,
#conversejs aside,
#conversejs audio,
#conversejs blockquote,
#conversejs caption,
#conversejs dd,
#conversejs details,
#conversejs div,
#conversejs dl,
#conversejs dt,
#conversejs em,
#conversejs embed,
#conversejs fieldset,
#conversejs figcaption,
#conversejs figure,
#conversejs footer,
#conversejs form,
#conversejs h1,
#conversejs h2,
#conversejs h3,
#conversejs h4,
#conversejs h5,
#conversejs h6,
#conversejs header,
#conversejs hgroup,
#conversejs img,
#conversejs legend,
#conversejs li,
#conversejs mark,
#conversejs menu,
#conversejs nav,
#conversejs ol,
#conversejs output,
#conversejs p,
#conversejs pre,
#conversejs ruby,
#conversejs section,
#conversejs span,
#conversejs strong,
#conversejs summary,
#conversejs table,
#conversejs tbody,
#conversejs td,
#conversejs tfoot,
#conversejs th,
#conversejs thead,
#conversejs time,
#conversejs tr,
#conversejs ul,
#conversejs video {
  font-family: "Vodafone Rg", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Vodafone Lt", sans-serif;
  color: #333333;
}

button {
  font-size: 21px;
  font-family: inherit;
  padding: 0.5rem 2rem;
  border-radius: 0.25rem;
  background-color: transparent;
  cursor: pointer;
}

.error-container {
  margin: 2rem auto;
  font-size: 1.5rem;
  font-weight: 700;
  color: red;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.modal-backdrop {
  z-index: 1030;
}

.chat-head,
.chat-info__message,
#conversejs .chat-head-chatroom,
#conversejs.converse-embedded .chat-head-chatroom {
  display: none;
}

#conversejs .chatbox .chat-content {
  background: #ebebeb;
}

.chat-body {
  z-index:1000;
}
#conversejs .suggestion-box {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.32);
  z-index: 10000;
  background: #ebebeb;
  height: 90px;
}

#conversejs .chatroom .sendXMPPMessage .send-button,
#conversejs.converse-embedded .chatroom .sendXMPPMessage .send-button {
  background-color: #E60000;
}

#conversejs .chatroom .sendXMPPMessage .send-button::before,
#conversejs.converse-embedded .chatroom .sendXMPPMessage .send-button::before {
  display: none;
}

#conversejs .chatroom .sendXMPPMessage .chat-textarea:active,
#conversejs .chatroom .sendXMPPMessage .chat-textarea:focus,
#conversejs.converse-embedded .chatroom .sendXMPPMessage .chat-textarea:active,
#conversejs.converse-embedded .chatroom .sendXMPPMessage .chat-textarea:focus {
  outline: 0;
}

#conversejs.converse-embedded .chatroom .box-flyout .chatroom-body .occupants,
#conversejs.converse-fullscreen .chatroom .box-flyout .chatroom-body .occupants,
#conversejs.converse-mobile .chatroom .box-flyout .chatroom-body .occupants {
  display: none;
}

#conversejs .chatroom-body .message .separator {
  border-bottom: 1px solid #ccc;
}

#conversejs .chatbox .sendXMPPMessage .chat-textarea {
  max-height: 45px;
  width: calc(100vw - 60px);
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  color: var(--chat-textarea-color);
  background-color: var(--chat-textarea-background-color);
  padding: 13px 55px 13px 16px;
  border: 1px solid #ccc;
  border-radius: 22px;
  margin: 20px 10px 20px 50px;
  min-height: 45px;
  height: 45px;
  resize: none;
  font-size: 14px;
}
    
#conversejs .chatbox .sendXMPPMessage .chat-textarea::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
    display: none;
    background: transparent; /* make scrollbar transparent */
}

#conversejs #controlbox {
  display: none;
}

#conversejs .chatbox .box-flyout {
  width: 100vw;
}

.web #conversejs .chatbox .box-flyout {
  height: 92%;
  top: 61px;
}

#no-messages {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 90vw;
  max-width: 100vw;
}

#no-messages p {
  text-align: center;
}

#no-messages p:first-child {
  text-align: center;
}

.btn-primary {
  background-color: #e60000;
  border-color: #e60000;
}

.btn-primary:disabled {
  background-color: #e60000;
  border-color: #e60000;
  opacity: 0.4;
}

.btn-primary:hover {
  background-color: #bd0000;
  border-color: #bd0000;
}

.btn-primary:focus {
  background-color: #bd0000;
  border-color: #bd0000;
  box-shadow: 0 0 0 0.2rem rgba(230, 0, 0, 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 0, 0, 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #bd0000;
  border-color: #bd0000;
}

#conversejs div.short-answer-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;
  gap: 10px;
  margin-left:40px;
}

#conversejs .short-answer-container button.short-answer-button {
  color: #4a4d4e;
  border: 1px solid #4a4d4e;
  padding: 7px 10px;
  font-size: 16px;
  border-radius: 15px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

#conversejs .short-answer-container button.short-answer-button:hover {
  background: #4a4d4e;
  color: #fff;
}

#conversejs .short-answer-container button.short-answer-button:first-child {
  margin-left: 0;
}

#conversejs .short-answer-container button.short-answer-button:last-child {
  margin-right: 0;
}

#conversejs .chat-msg__heading {
  margin-left: 40px;
}

.spinner-custom-container {
  display: flex;
}

.spinner-custom-group {
  margin: auto !important;
  z-index: 3;
}

.spinner, .spinner-custom {
  animation: spin 2s infinite, linear;
  display: block;
  font-size: 24px;
}

.spinner-custom {
  width: 50px;
  height: 50px;
  margin: 5px auto !important;
}

.spinner-custom-after {
  z-index: 200;
}

.spinner-text {
  padding: 0 !important;
  font-size: 14px;
  text-align: center;
  margin-top: 15px !important;
}

.spinner-text-app {
  margin-bottom: 15px !important;
}

.web .spinner-text-app {
  margin-bottom: 80px !important;
}

.spinner-text-after {
  z-index: 200;
  margin-bottom: 15px !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner-new {
  color: #a8aba1;
  animation: spin 2s infinite, linear;
  width: 1em;
  display: block;
  font-size: 24px;
  transform: translate(-50%, -50%);
  margin: auto;
}

.new-msgs-indicator {
  display: none;
}

#conversejs {
  z-index: 1;
}

.chat-content__messages {
  z-index: 3;
}

body:not(.safari) .sendXMPPMessage {
  z-index: 5;
}

body.keyboard .sendXMPPMessage {
  z-index: 5;
}

.warning-message-container {
  color: #D8000C;
  border: 1px solid #D8000C;
  margin:10px;
  padding: 10px;
  font-size:1em;
  vertical-align:middle;
}

.warning-message-container a{
  color: #D8000C;
  text-decoration: underline;
}

.fa-exclamation-circle {
  width: 25px;
}

.tobi-header {
  height: 75px;
  margin-bottom: 10px;
}

.tobi-bottom-icon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -15px;
  background: white;
  border-radius: 50%;
  left: 7px;
  padding: 5px !important
}

.avatar-layout {
  display: flex;
}

.tobi-avatar {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  padding: 5px !important;
  margin-right: 5px !important;
  align-self: flex-end;
}

.tobi-avatar.offer-avatar {
  align-self: flex-start;
}

.scroll-bottom-btn {
  border-radius: 50% !important;
	margin-right: 10px;
	color: black !important;
	height: 30px;
	width: 30px;
	background-color: white;
	padding: 7px 0px;
	border: 1px solid black;
	position: absolute;
	right: 0;
  bottom: 100px;
  cursor: pointer;
  z-index: 4;
}

.scroll-bottom-btn.hide-btn {
  display: none !important;
}

.scroll-bottom-btn.show-btn {
  display: block;
}

.change-identity {
  cursor: pointer;
}

.change-identity:hover h5 {
  color: #e60000;
}

.back-button-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 61px !important;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color:  rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 3;
}

.back-button {
  display: block !important;
  position: sticky;
  top: 8px;
  z-index: 2;
}

button.btn.send-button {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  margin-top: 28px;
  margin-right: 20px;
}

.converse-embedded.web {
  overflow: auto;
}

.tobi-maintenance-avatar {
  height: 75px;
  width: 75px;
}

.row>* {
  padding-left: 0px;
  padding-right: 0px;
}

.modal-content {
  background-color: #e60000;
  color: white;
}
  
.modal-body {
  padding: 0.5rem;
}
  
.modal-header {
  border-bottom: none;
  align-items: unset;
}
  
.modal-header .btn-close, .modal-header .btn-close:hover {
  font-size: 16px;
}

.modal-body a, .modal-body a:hover {
  color: white;
  text-decoration: underline;
}

.conversejs .message.chat-msg .chat-msg__body--me {
  margin-right: 10px !important;
}

.conversejs .message.chat-msg .chat-msg__body--them {
  width: fit-content;
}

.conversejs .chat-msg__content--carousel .chat-msg__body--them {
  margin-right: 30px !important;
}

.modal-header-gdpr {
  flex-wrap: wrap-reverse;
}

.arrow-invisible {
  display: none;
}

body.mobile span.scroll-arrow {
  display: none;
}

body.windows #conversejs span.scroll-arrow.right-arrow {
  right: 18px;
}

@media (max-width: 300px) {

  #conversejs .chatbox .sendXMPPMessage .chat-textarea {
    margin-left: 40px;
  }

  button.btn.send-button {
    margin-right:35px;
  }
}

@media (min-width: 601px) {
  .converse-embedded.web {
    overflow: hidden;
  }
}

@media (max-width: 365px) {
  .tobi-avatar {
    display: none;
  }

  .conversejs .message.chat-msg {
    margin-left: 10px;
  }

  #conversejs div.short-answer-container, #conversejs .chat-msg__heading {
    margin-left: 0;
  }
}

@media (max-width: 350px) {
  .card-buttons {
    flex-direction: column;
  }

  #conversejs .short-answer-container button.short-answer-button {
    font-size: 15px;
  }

  .tobi-maintenance-body {
    font-size: 1rem;
  }

  .tobi-maintenance-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  #conversejs.converse-embedded .chatbox,
  #conversejs.converse-fullscreen .chatbox {
    flex: 0 0 100vw;
    max-width: 100vw;
  }
}

@media screen and (min-width: 1023px) {
  body {
    height: 99vh;
  }
}

@media screen and (max-height: 740px) {
  .web #conversejs .chatbox .box-flyout {
    height: 90%;
  }
}

@media screen and (max-height: 620px) {
  .web #conversejs .chatbox .box-flyout {
    height: 88%;
  }
}

@media screen and (max-height: 504px) {
  .web #conversejs .chatbox .box-flyout {
    height: 86%;
  }
}

@media screen and (max-height: 431px) {
  .web #conversejs .chatbox .box-flyout {
    height: 84%;
  }
}

@media screen and (max-height: 378px) {
  .web #conversejs .chatbox .box-flyout {
    height: 82%;
  }
}
